<template>
  <div id="app">

    <Navigation v-if="authenticated" />

    <router-view v-else />


  </div>
</template>


<script>
  import { mapGetters } from 'vuex'

  import Navigation from '@/components/Navigation.vue';



  export default {

    created() {

      if (this.authenticated)
        this.$router.push({ name: 'Dashboard'});

    },

    components: {
      Navigation,
    },

    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
        connected: 'connection/connected',
      })
      
    },

  }



</script>
