import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
Vue.component('progress-circle', require('../components/ProgressCircle.vue').default);
Vue.component('loading-divider', require('../components/LoadingDivider.vue').default);

Vue.component('simple-pagination', require('../components/SimplePagination.vue').default);
Vue.component('save-button', require('../components/buttons/SaveButton.vue').default);

Vue.component('search-select', require('../components/forms/SearchSelect.vue').default);
Vue.component('grow-area', require('../components/forms/GrowArea.vue').default);
Vue.component('photo-grow-area', require('../components/forms/PhotoGrowArea.vue').default);


export default {}
